import getValueDeep from '../getValueDeep.js';

/**
 * @typedef {{ [needle:string]: string }} Replacement
 */

export default {
  methods: {
    /**
     * Translate the given key.
     * @param {string} key
     * @param {{number: string}} [replace]
     * @param {string} [domain]
     */
    __(key, replace = {}, domain = 'default') {
      if (typeof replace === 'string') {
        // eslint-disable-next-line no-param-reassign
        domain = replace;
        // eslint-disable-next-line no-param-reassign
        replace = {};
      }

      let translation =
        getValueDeep(this.$page.props.language, key) ??
        getValueDeep(this.$page.props.language[domain], key) ??
        key;

      Object.entries(replace).forEach(([prop, value]) => {
        translation = translation.replace(`:${prop}`, value);
      });

      return translation;
    },

    /**
     * Translate the given key with basic pluralization.
     */
    __n(key, number, replace = {}, domain = 'default') {
      const [singular, plural] = key.split('|');
      return this.__(number > 1 ? plural : singular, replace, domain);
    },
  },
};
