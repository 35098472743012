import axios from 'axios';
import Cache from './Cache.js';

/**
 * @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig
 * @typedef {import('axios').AxiosResponse} AxiosResponse
 */

const cache = new Cache({ name: 'axios', ttl: 5 });
let timer;

/**
 * Cache only the `?filters` GET requests.
 * @param {AxiosRequestConfig} request
 * @returns {boolean}
 */
function shouldCacheRequest(request) {
  return request.method.toLowerCase() === 'get' && request.url.includes('/domaines');
}

/**
 * Get a uniq cache key based on the request.
 *
 * @param {AxiosRequestConfig} request
 * @returns {string}
 */
function getCacheKey(request) {
  const { method, url } = request;
  return `${method}-${url}`;
}

/**
 * Axios request interceptor to serve data from cache.
 *
 * @link https://gist.github.com/javisperez/4bb09e0437994a659bbcd06f90eeebbf
 * @param {AxiosRequestConfig} request
 * @returns {AxiosRequestConfig}
 */
export function getDataFromCache(request) {
  if (!shouldCacheRequest(request)) {
    return request;
  }

  const cacheKey = getCacheKey(request);
  const cached = cache.get(cacheKey);

  if (!cached) {
    return request;
  }

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.log('getDataFromCache', request, cached);
  }

  clearTimeout(timer);
  document.dispatchEvent(new CustomEvent('inertia:start'));

  request.adapter = () => {
    return new Promise((resolve) => {
      timer = setTimeout(() => {
        resolve({
          data: cached,
          status: request.status,
          statusText: request.statusText,
          headers: {
            ...request.headers,
            'x-inertia': true,
          },
          config: request,
          request,
        });
        document.dispatchEvent(
          new CustomEvent('inertia:finish', { detail: { visit: { completed: true } } })
        );
      }, 100);
    });
  };

  return request;
}

/**
 * Axios response interceptor to save data to the cache.
 *
 * @link https://gist.github.com/javisperez/4bb09e0437994a659bbcd06f90eeebbf
 * @param {AxiosResponse} request
 */
export function saveDataToCache(response) {
  if (!shouldCacheRequest(response.config)) {
    return response;
  }

  const cacheKey = getCacheKey(response.config);

  let { data } = response;
  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch (err) {
      /* Silence is golden. */
    }
  }

  cache.set(cacheKey, data);
  return response;
}

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.interceptors.request.use(getDataFromCache);
// axios.interceptors.response.use(saveDataToCache);

export default axios;
