/**
 * @typedef {Object} ParsedURL
 * @property {string} hash
 * @property {string} host
 * @property {string} hostname
 * @property {string} href
 * @property {string} origin
 * @property {string} password
 * @property {string} pathname
 * @property {string} port
 * @property {string} protocol
 * @property {string} search
 * @property {string} username
 * @property {URLSearchParams} searchParams
 * @property {() => string} toString
 * @property {() => string} toJSON
 */

/**
 * Parse a URL and get a location object.
 * @param {string} url The URL to parse
 * @returns {ParsedURL}
 */
export default function parseUrl(url) {
  const a = document.createElement('a');
  a.href = url;

  const location = {
    toString() {
      return a.href;
    },
    toJSON() {
      return a.href;
    },
    get searchParams() {
      return new URLSearchParams(a.search);
    },
  };

  const props = [
    'hash',
    'host',
    'hostname',
    'href',
    'origin',
    'password',
    'pathname',
    'port',
    'protocol',
    'search',
    'username',
  ];

  props.forEach((prop) => {
    const attributes = {
      get() {
        return a[prop];
      },
      set(value) {
        a[prop] = value;
      },
      enumerable: true,
    };

    // origin is read only
    if (prop === 'origin') {
      delete attributes.set;
    }

    Object.defineProperty(location, prop, attributes);
  });

  return location;
}
