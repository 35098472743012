import Vue from 'vue';
import debounce from '@studiometa/js-toolkit/utils/debounce.js';

/**
 * Get the list of available breakpoints and if they are active or not.
 *
 * Based on the screens defined in our @studiometa/tailwind-config package,
 * when the viewport has a width of 800px, this function will return the
 * following object:
 *
 * ```js
 * {
 *   xxs: true,
 *   xs: true,
 *   s: true,
 *   m: false,
 *   l: false,
 *   xl: false,
 *   xxl: false,
 * }
 * ```
 *
 * @returns {Record<string, boolean>}
 */
function getBreakpoints() {
  const div = document.querySelector('[data-breakpoint]');

  if (!div) {
    return {};
  }

  const before = window.getComputedStyle(div, '::before');
  const after = window.getComputedStyle(div, '::after');

  if (!after || !before) {
    return {};
  }

  const breakpointNames = after.getPropertyValue('content').replace(/"/g, '').split(',');
  return breakpointNames.reduce((acc, breakpointName) => {
    acc[breakpointName] = Number(before.getPropertyValue(`--screen-${breakpointName}`)) === 1;
    return acc;
  }, {});
}

/**
 * Media queries helper based on the defined `$breakpoints` map from the
 * SCSS Toolkit.
 *
 * This plugin defines an `$mq` object accessible through the app. This object
 * is reactive and contains the following properties:
 *
 * Example usage:
 *
 * ```js
 * mq.breakpoints // { s: true, m: false, l: false }
 * mq.is // { s: true, m: false, l: false }
 * mq.isOneOf(['m', 'l']) // false
 * mq.isOneOf('m l') // false
 * mq.isOneOf('s m') // true
 * mq.isAllOf('s m') // true
 * mq.isAllOf('s') // true
 * ```
 *
 * ```vue
 * <my-mobile-component v-if="$mq.is.s" />
 * <my-desktop-component v-if="$mq.isOneOf('m l')" />
 * <my-desktop-component v-if="$mq.isAllOf('m l')" />
 * ````
 *
 * @see https://tailwind-config.meta.fr/plugins/breakpoint.html
 * @returns {typeof Vue}
 */
function createMq() {
  const mq = new Vue({
    data: () => ({ is: getBreakpoints() }),
    computed: {
      breakpoints() {
        return Object.keys(this.is);
      },
    },
    methods: {
      /**
       * Test if the current breakpoint is in the given list of breakpoints.
       * @param {string|string[]} breakpoints The breakpoints to test.
       * @returns {boolean}
       */
      isOneOf(breakpoints) {
        if (typeof breakpoints === 'string') {
          // eslint-disable-next-line no-param-reassign
          breakpoints = breakpoints.split(' ');
        }

        return breakpoints.some((breakpoint) => this.is[breakpoint]);
      },

      /**
       * Test if the current active breakpoints are the same as the list given.
       * @param {string|string[]} breakpoints The breakpoints to test.
       * @returns {boolean}
       */
      isAllOf(breakpoints) {
        if (typeof breakpoints === 'string') {
          // eslint-disable-next-line no-param-reassign
          breakpoints = breakpoints.split(' ');
        }

        return breakpoints.reduce((acc, breakpoint) => acc && this.is[breakpoint], true);
      },
    },
  });

  window.addEventListener(
    'resize',
    debounce(() => {
      mq.is = getBreakpoints();
    }, 100)
  );

  return mq;
}

export default {
  /**
   * Install the Vue plugin.
   * @param {typeof Vue} vm The Vue object to extend.
   */
  install(vm) {
    vm.prototype.$mq = createMq();
  },
};
