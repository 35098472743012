import Vue from 'vue';
import { App as InertiaApp, plugin as InertiaPlugin, Link } from '@inertiajs/inertia-vue';
import { InertiaProgress } from '@inertiajs/progress';
import PortalVue from 'portal-vue';
import VueTwicpics from '@studiometa/vue-twicpics';
import VueMeta from 'vue-meta';
import './utils/axios.js';
import './utils/plugins/o-embed/index.js';

import * as elements from './Elements/index.js';
import mediaQueries from './utils/plugins/mediaQueries.js';
import translation from './utils/mixins/translation.js';

if (process.env.NODE_ENV === 'production') {
  (async () => {
    // Enable lazy Sentry in production
    const Sentry = await import('@sentry/vue');
    const { Integrations } = await import('@sentry/tracing');

    Sentry.init({
      Vue,
      dsn: 'https://30e9921411314d01810941fdf56dc4fb@o262143.ingest.sentry.io/5692914',
      integrations: [new Integrations.BrowserTracing()],
      environment:
        window.location.hostname === 'www.millesimes-alsace.com'
          ? 'production'
          : window.location.hostname,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  })();
}

Vue.component('InertiaLink', Link);

Vue.use(VueMeta);
Vue.use(mediaQueries);
Vue.mixin({ methods: { route: window.route, $route: window.route } });
Vue.mixin(translation);
Vue.use(InertiaPlugin);
Vue.use(PortalVue);
Vue.use(VueTwicpics, {
  domain: 'https://cdn.studiometa.fr',
  defaultParams: {
    maxDpr: 2,
    step: 50,
  },
});

InertiaProgress.init({
  includeCSS: false,
  showSpinner: true,
  delay: 100,
});

// Register elements globally
Object.entries(elements).forEach(([name, configuration]) => {
  Vue.component(name, configuration);
});

const app = document.querySelector('#app');
const initialPage = JSON.parse(app.dataset.page);

new Vue({
  render: (h) =>
    h(InertiaApp, {
      props: {
        initialPage,
        resolveComponent: (name) => import(`./Pages/${name}.vue`).then((module) => module.default),
      },
    }),
}).$mount(app);
